import misc from './misc';

class subscriptionsSummaryMobile {
    constructor(){

		if(!document.querySelector('.toggle-item-summary'))
			return false;
        
        // List of handlers
        this.toggleOnClick = this.toggleOnClick.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.enableEscKey = this.enableEscKey.bind(this);

        this.trigger = document.querySelector('.toggle-item-summary');
        this.summaryWrapper = document.querySelector('#subscriptions-summary')
        this.mainWrapper = document.querySelector('main')
        this.currentTarget = document.querySelector('.toggle-item-summary')
		
		this.trigger.addEventListener('click', this.toggleOnClick );
		window.addEventListener('resize', this.onWindowResize)

	}

	toggleOnClick( event ){
		const currentTarget = event.currentTarget

        // STEP 3 and STEP 4, it works just on mobile
        if( (this.trigger.classList.contains('toggle-item-summary-step-3') ||
            this.trigger.classList.contains('toggle-item-summary-step-4') )
            && misc.getViewport().width > 900 )
            return false;

		// This will stop people clicking like crazy
		if( currentTarget.classList.contains('on-toggle') ){
			return false
		}
		
        if(!currentTarget.classList.contains('active') )
			this.openSummary();
		else
			this.closeSummary();
	}

	openSummary(){
		this.currentTarget.classList.add('active')
		this.currentTarget.classList.add('on-toggle')

		const summaryWrapperHeight = this.summaryWrapper.querySelector('.item-summary-inner').getBoundingClientRect().height

		this.mainWrapper.classList.add('item-summary-active')
		this.mainWrapper.classList.add('item-summary-on-transition')

		const afterAnimation = () => {
			this.summaryWrapper.classList.add('active');
			this.mainWrapper.classList.remove('item-summary-on-transition')
			this.summaryWrapper.style.height = `${summaryWrapperHeight}px`;
			this.currentTarget.classList.remove('on-toggle')
            
            // Custom listener to add just when the summary is open
            const events = ['click','touchstart']
            events.forEach( item => {
                document.addEventListener(item, this.handleOutsideClick );
            })
            document.addEventListener('keyup', this.enableEscKey );

			this.mainWrapper
				.removeEventListener(misc.getAnimations(), afterAnimation );
		}

		misc.getAnimations() && this.mainWrapper
			.addEventListener(misc.getAnimations(), afterAnimation );
	}

	closeSummary(){
        
		this.currentTarget.classList.add('on-toggle')

		// This action triggers a transition
		this.summaryWrapper.classList.remove('active');


        const afterTransition = () => {

            // This action triggers an animation
            this.mainWrapper.classList.add('item-summary-on-transition-reverse');

            // We call the animation
            misc.getAnimations() && this.mainWrapper
                .addEventListener(misc.getAnimations(), afterAnimation );

            this.summaryWrapper
                .removeEventListener(misc.getTransitions(), afterTransition );
        }

        // We call the Transition
        misc.getTransitions() && this.summaryWrapper
            .addEventListener(misc.getTransitions() , afterTransition );


        const afterAnimation = () => {
            
            this.mainWrapper.classList.remove('item-summary-on-transition-reverse');
            this.mainWrapper.classList.remove('item-summary-active');
            this.currentTarget.classList.remove('on-toggle');
            this.currentTarget.classList.remove('active')
            this.summaryWrapper.style.height = '';

            const events = ['click','touchstart']
            events.forEach( item => {
                document.removeEventListener(item, this.handleOutsideClick );
            })
            document.removeEventListener('keyup', this.enableEscKey );
            this.mainWrapper
                .removeEventListener(misc.getAnimations(), afterAnimation );
        }

    }

    enableEscKey(event){
        if( event.keyCode === 27 && !misc.isLightboxOpen() ){
            this.closeSummary();
        }
    }

    handleOutsideClick(event){
        
        misc.hasClickedOutside(this.summaryWrapper, event.target).then( () => {
            if(!misc.isLightboxOpen()){
                this.closeSummary()
            }
		})
	}

	onWindowResize(){
		const currentTarget = document.querySelector('.toggle-item-summary')
		// We do a hard reset, no animation no mercy
		if( misc.getViewport().width > 900 && currentTarget.classList.contains('active') ){
			currentTarget.classList.remove('active')
			this.summaryWrapper.classList.remove('active');
			this.mainWrapper.classList.remove('item-summary-on-transition-reverse')
			this.mainWrapper.classList.remove('item-summary-active')
			this.summaryWrapper.style.height = ''
            const events = ['click','touchstart']
            events.forEach( item => {
                document.removeEventListener(item, this.handleOutsideClick );
            })
		}
	}

}

const toggleSummaryOnMobile = new subscriptionsSummaryMobile()

// Debug
// document.addEventListener('DOMContentLoaded', function(){
//     document.querySelector('.toggle-item-summary').click()    
// });