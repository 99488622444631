import misc from './misc';
import CloseBoxNotification from './box-notifications';

class SubscriptionsSelectLuggage {
  constructor() {
    this.list = [].slice.call(document.querySelectorAll('.subscriptions-luggage-list .subscriptions-luggage-item .btn'));
    this.notificationBox = document.querySelector('#plan-notification .box-notification');

    // List of handlers
    this.selectOnClick = this.selectOnClick.bind(this);
    this.removeOnClick = this.removeOnClick.bind(this);

    this.list.forEach(item => {
      item.addEventListener('click', this.selectOnClick);
    });

    this.checkAddonsFromSession();
  }

  selectOnClick(event) {
    event.preventDefault();

    const itemLuggage = document.querySelector(`#${event.target.dataset.itemId}`);
    const itemAddon = itemLuggage.querySelector('.checkbox-luggage-item');

    // Do nothing if is selected
    if (itemLuggage.classList.contains('item-selected')) { return false; }

    itemLuggage.classList.add('item-selected');
    itemLuggage.querySelector('h4').classList.add('icon-checked');
    itemLuggage.querySelector('.btn').classList.add('btn-green');
    itemLuggage.querySelector('.btn').classList.remove('btn-purple');
    itemLuggage.querySelector('.btn').innerHTML = document.querySelector('.subscriptions-luggage-list').dataset.itemSelected;
    itemLuggage.querySelector('.item-subscriptions-remove').addEventListener('click', this.removeOnClick);
    itemAddon.checked = true;
    setTimeout(() => { this.displayNotificationBox(); }, 600);
  }

  removeOnClick(event) {
    event.preventDefault();

    const itemLuggage = document.querySelector(`#${event.currentTarget.dataset.divId}`);
    const itemAddon = itemLuggage.querySelector('.checkbox-luggage-item');

    itemLuggage.classList.remove('item-selected');
    itemLuggage.querySelector('h4').classList.remove('icon-checked');
    itemLuggage.querySelector('.btn').classList.remove('btn-green');
    itemLuggage.querySelector('.btn').classList.add('btn-purple');
    itemLuggage.querySelector('.btn').innerHTML = document.querySelector('.subscriptions-luggage-list').dataset.itemToSelect;
    itemAddon.checked = false;
  }

  displayNotificationBox() {
    if (this.notificationBox.classList.contains('active')) { return false; }

    this.notificationBox.style.display = '';
    this.notificationBox.classList.add('on-transition');
    this.notificationBox.style.height = `${this.notificationBox.querySelector('.entry-content').getBoundingClientRect().height}px`;

    const afterTransition = () => {
      this.notificationBox.classList.remove('on-transition');
      this.notificationBox.classList.add('active');
      this.notificationBox.style.height = '';

      // eslint-disable-next-line
      const closeBoxNotification = new CloseBoxNotification({
        element: '#plan-notification .box-notification',
        onfinish: function(animation, notificationBox) {
          notificationBox.classList.remove('active');
        }
      });

      // This action should be always at the end
      this.notificationBox.removeEventListener(misc.getTransitions(), afterTransition);
    };

    misc.getTransitions() && this.notificationBox
      .addEventListener(misc.getTransitions(), afterTransition);
  }

  checkAddonsFromSession() {
    // See: 'plan.blade.php'
    const { selectedAddons } = window.Cvo;

    if (typeof selectedAddons === 'undefined') {
      return false;
    }

    const node = document.querySelectorAll('.subscriptions-luggage-list .subscriptions-luggage-item');
    const list = [].slice.call(node);

    selectedAddons.forEach((element) => {
      list[element].querySelector('.btn').click();
    });
  }
}

// eslint-disable-next-line
const subscriptionsSelectLuggage = new SubscriptionsSelectLuggage();
