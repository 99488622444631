import en from './en.json';
import es from './es.json';

// Locale in English and Spanish
const trans = {
    'en-us': {
        ...en
    },
    'es-mx': {
        ...es
    }
};

export default trans;