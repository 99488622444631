import Axios from 'axios';

const _getStates = Symbol('_getStates');
const _resetSelect = Symbol('_resetSelect');
const _toggleEmpty = Symbol('_toggleEmpty');

export class CountrySelect {
  constructor(options) {
    this.countries = options.countries ? document.querySelector(options.countries) : null;
    if (!this.countries) {
      throw new Error(`Countries select ${this.countries} not found`);
    }

    this.states = options.states ? document.querySelector(options.states) : null;
    if (!this.states) {
      throw new Error(`States select ${this.states} not found`);
    }

    this.baseUrl = options.baseUrl ? options.baseUrl : null;
    if (!this.baseUrl) {
      throw new Error('Base URL not configured');
    }

    this.default = options.default ? document.querySelector(options.default) : document.getElementById(this.states.id + 'Default');
    this.notEmptyClass = options.notEmptyClass ? options.notEmptyClass : null;
    this.emptyClass = options.emptyClass ? options.emptyClass : null;
    this.disabled = (options.disabled === true);
    this.required = (options.required === true);

    // Validation integration
    // FIXME: incapsulation broken
    this.validClass = options.validClass || null;
    this.invalidClass = options.invalidClass || null;

    this.requestConfig = {
      baseURL: this.baseUrl,
      headers: { 'Accept': 'application/json' },
    };

    this.call = null;
  }

  init() {
    this.countries.addEventListener('change', (evt) => this[_getStates](evt));
    this.countries.dispatchEvent(new Event('change'));
  }

  [_getStates](evt) {
    // Reset the select with an empty option
    this[_resetSelect]();

    var country = evt.target.value;
    if (!country) {
      // Prevent unuseful calls
      return;
    }

    // Retrieve new data (debounced)
    if (this.call) {
      this.call.cancel();
    }
    this.call = Axios.CancelToken.source();

    Axios
      .create(this.requestConfig)
      .get(country, { cancelToken: this.call.token })
      .then((response) => {
        // Validation integration
        // FIXME: incapsulation broken. And for unknown reasons, multiple `then` are not always
        // executed, so packing all this unrelated code in the same handler.
        if (this.validClass) {
          this.states.classList.remove(this.validClass);
        }
        if (this.invalidClass) {
          this.states.classList.remove(this.invalidClass);
        }
        this.states.dispatchEvent(new Event('input', { bubbles: true }));

        var stateCodes = Object.keys(response.data);

        if (stateCodes.length > 0) {
          this[_toggleEmpty](false);

          for (let i = 0; i < stateCodes.length; i++) {
            const sc = stateCodes[i];

            const option = document.createElement('option');
            option.value = sc;
            option.text = response.data[sc];
            this.states.add(option);
            if (this.default && this.default.value === sc) {
              option.selected = true;
            }
          }

        } else {
          this[_toggleEmpty](true);
        }

        // Notify the select change
        this.states.dispatchEvent(new Event('input', { bubbles: true }));

      })
      .catch(() => {
        // What?
      })
      .then(() => {
        this.call = null;
      });
  }

  [_resetSelect]() {
    this.states.innerHTML = '';
    const option = document.createElement('option');
    option.value = '';
    option.text = '-';
    option.selected = true;
    option.disabled = true;
    this.states.add(option);
    // Notify the select change (using input to match the other validator this class shouldn't be aware of...)
    this.states.dispatchEvent(new Event('input'));
  }

  [_toggleEmpty](isEmpty) {
    if (isEmpty) {
      // Disable and hide State selection
      this.states.disabled = this.disabled;
      this.states.required = false;
      if (this.notEmptyClass) {
        this.states.classList.remove(this.notEmptyClass);
        this.states.labels.forEach((l) => l.classList.remove(this.notEmptyClass));
      }
      if (this.emptyClass) {
        this.states.classList.add(this.emptyClass);
        this.states.labels.forEach((l) => l.classList.add(this.emptyClass));
      }

    } else {
      // Enable and show State selection
      this.states.disabled = false;
      this.states.required = this.required;
      if (this.notEmptyClass) {
        this.states.classList.add(this.notEmptyClass);
        this.states.labels.forEach((l) => l.classList.add(this.notEmptyClass));
      }
      if (this.emptyClass) {
        this.states.classList.remove(this.emptyClass);
        this.states.labels.forEach((l) => l.classList.remove(this.emptyClass));
      }
    }
  }

}
