/**
 * Enables the submit button of a form is any or all checkboxes are checked
 * Receives an object on create with this values:
 *  + id: identificator of the form that contain checkboxes and submit
 *  + checkbox: identificator of the checkboxes (usually a class name)
 *  + anyCheckbox: boolean to indicate if with any checkbox is enough to enable or is needed all checked
 *  + requiredText: boolean to indicate if the input texts marked as required are needed to enable
 *  + submit: identificator of the button to sumbit
 */
export default class EnableOnAgreement {

  constructor(options) {
    // check of mandatory values
    if (document.querySelector(options.checkbox) === null ||
        document.querySelector(options.id) === null ||
        document.querySelector(options.submit) === null) {
      return false;
    }

    this.formId = document.querySelector(options.id);
    this.inputSubmit = document.querySelector(options.submit);
    this.checkBoxesSelector = options.checkbox;

    // checks if is defined the boolean to check any or all checkboxes
    this.anyCheckbox = options.anyCheckbox !== undefined ? options.anyCheckbox : true;

    // checks if is defined the boolean to check the required text inputs
    this.requiredText = options.requiredText !== undefined ? options.requiredText : false;

    // binds an event on change to checkboxes and, if it's necessary, the text inputs.
    this.formId.querySelectorAll(this.checkBoxesSelector).forEach(item => {
      this.addChangeEvent(item);
    });
    if (this.requiredText) {
      this.formId.querySelectorAll('input[type="text"][required]').forEach(item => {
        this.addChangeEvent(item);
      });
    }
  }

  /**
   * adds an event listener for 'change' event to a DOM element
   * @param {DOM Element} item the element to bind the event listener
   */
  addChangeEvent(item) {
    item.addEventListener('change', () => {
      this.toggleSubmit(this.shouldEnable());
    });

    item.dispatchEvent(new Event('change'));
  }

  /**
   * changes the state of disabled to the submit button
   * @param {Boolean} enable true or false, to change the state
   */
  toggleSubmit(enable) {
    this.inputSubmit.disabled = !enable;
  }

  /**
   * returns a boolean value to indicate if must enable or not the submit button
   */
  shouldEnable() {
    const allCheckboxes = this.formId.querySelectorAll(this.checkBoxesSelector).length;
    const allCheckboxesChecked = this.formId.querySelectorAll(`${this.checkBoxesSelector}:checked`).length;
    const allInputText = this.formId.querySelectorAll('input[type="text"][required]').length;
    const allInputTextValid = this.formId.querySelectorAll('input[type="text"][required]:valid').length;

    const okCheckboxes = this.anyCheckbox ? (allCheckboxesChecked > 0) : (allCheckboxes === allCheckboxesChecked);
    const okInputText = (allInputText === allInputTextValid);

    if (this.requiredText) {
      return (okCheckboxes && okInputText);
    } else {
      return okCheckboxes;
    }
  }
}
