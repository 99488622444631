import { CountrySelect } from './form-countrySelect';
import EnableOnAgreement from './form-enableOnAgreement';
import { FormValidator } from './form-validation/form-validator';
import { FieldsetDateValidator } from './form-validation/validator-fieldset-date';
import { ValueMatchValidator } from './form-validation/validator-value-match';

// FIXME: we need per-page entry points to allow page-specific execution without relying
// on DOM elements presence (and have to deal on cross-page id uniqueness...)

const _prefillBirthDate = Symbol('_prefillBirthDate');
const _gatherBirthDate = Symbol('_gatherBirthDate');
const _makeFormReadOnly = Symbol('_makeFormReadOnly');

class PageRegister {

  init() {
    const formValidator = new FormValidator({
      formId: 'form-register',
      submitId: 'register-submit',
      validateOnInput: false,
      // onSubmit: [
      //   new DateOfBirth(),
      //   new Password(),
      //   new PhoneNumber()
      // ],
    });
    formValidator.init();

    const form = document.getElementById('form-register');

    // Due to the keycloak validation process, the registration form changes
    // for the email validation form we don't need the validations bellow
    const isEmailValidation = form.hasAttribute('data-email-validation');
    if (isEmailValidation) {
      return;
    }

    const passwordValidator = new ValueMatchValidator({ errorType: 'passwordMismatch' });
    formValidator.registerCustomValidator('value-match', passwordValidator);

    const birthdateValidator = new FieldsetDateValidator({
      fieldsetId: 'birthdate-fs',
      dayId: 'birthdayDay',
      monthId: 'birthdayMonth',
      yearId: 'birthdayYear',
      errorType: 'dateInvalid',
      copyToId: 'dateOfBirth',
      minAge: 18
    });
    formValidator.registerCustomValidator('birthdate', birthdateValidator);

    const countrySelect = new CountrySelect({
      countries: '#country',
      states: '#provinceState',
      baseUrl: document.location.origin + '/y4/states/',
      disabled: true,
      required: true,
      emptyClass: 'disabled',
      // notEmptyClass: 'required',
      validClass: 'valid',
      invalidClass: 'invalid'
    });
    countrySelect.init();

    // Enable checkbox agreement
    const agreedOnRegister = new EnableOnAgreement({
      id: '#form-register',
      checkbox: '.field-termsconditions input',
      submit: '#register-submit'
    });

    this[_prefillBirthDate](form);

    form.addEventListener('submit', this[_gatherBirthDate]);

    // Make non-blank form fields readonly on upgrade
    if (form.upgrade && form.upgrade.value === '1') {
      this._makeFormReadOnly(form);
    }
  }

  [_prefillBirthDate](form) {
    if (form.dateOfBirth.value) {
      var dateParts = form.dateOfBirth.value.split('-');
      form.birthdayYear.value = dateParts[0] / 1;
      form.birthdayMonth.value = dateParts[1] / 1;
      form.birthdayDay.value = dateParts[2];
    }
  }

  [_gatherBirthDate](e) {
    var form = e.target;
    var y = form.birthdayYear.value;
    var m = form.birthdayMonth.value;
    var d = form.birthdayDay.value;

    form.dateOfBirth.value = `${y}-${m}-${d}`;
  }

  [_makeFormReadOnly](form) {
    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      if (element.value !== '') {
        element.readOnly = true;
      }
    }
  }

}

/**
 * Shows how much time left is needed to send the
 * validation email
 */
function startValidationTimeout() {
  if(window.CVO.timeLeft > 0) {
    const timeInterval = setInterval(() => {
      const ms = window.CVO.timeLeft <= 0 ? 0 : window.CVO.timeLeft;
      const minutes = Math.floor(ms / 60000);
      const seconds = ((ms % 60000) / 1000).toFixed(0);
      const result = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;

      const domElement = document.getElementById('time-left');
      domElement.innerText = result;

      if(ms === 0){
        clearInterval(timeInterval);
        document.getElementById('register-submit').removeAttribute('disabled');
        console.log('interval clean');
      }

      window.CVO.timeLeft = window.CVO.timeLeft - 1000;
    }, 1000);
  }
}

if (document.getElementById('form-register')) {
  const volarisPageRegister = new PageRegister();
  volarisPageRegister.init();

  if (window.CVO.timeLeft && document.getElementById('time-left')) {
    startValidationTimeout();
  }
}
