// import AdyenCheckout from 'adyenCheckout';
import Axios from 'axios';
import { FormCopier } from './form-copier';
import { CountrySelect } from './form-countrySelect';
import { FormValidator } from './form-validation/form-validator';
import paymentCommons from './payment-form-commons.js';
import SpreedlyHandler from './spreedly-handler';
import SpreedlyValidationsHandler from './spreedly-validations';
import trans from './locale/translation';

// FIXME: we need per-page entry points to allow page-specific execution without relying
// on DOM elements presence (and have to deal on cross-page id uniqueness...)

let paymentForm = document.getElementById('form-payment') || document.getElementById('form-edit-payment');
let PAYMENT_FORM_ID = paymentForm && paymentForm.getAttribute('id');
let PAYMENT_SUBMIT_ID = paymentForm && paymentForm.querySelector('button[type="submit"]').getAttribute('id');

let spreedlyHandler = null;
let spreedlyValidationHandler = null;
let spreedlyValidationError = false;
let spreedlyValidationMessage = null;
let spreedlyValidationOK = false;
let tokenizedPayload = null;

const spreedlyFormIds = {
  token: 'payment-method-token',
  card: 'spreedly-number',
  month: 'spreedly-month',
  year: 'spreedly-year',
  cvv: 'spreedly-cvv',
  name: 'spreedly-full-name',
};

const paymentFormIds = {
  firstName: 'billingFirstName',
  lastName: 'billingLastName',
  email: 'billingEmail',
  phoneNumber: 'telephoneNumber',
  address: 'billingAddress',
  postCode: 'billingPostalCode',
  country: 'billingCountry',
  provinceState: 'billingStateDefault',
  city: 'billingCity',
};

const config = {
  environment: null,
  inputSelector: '.CvoForm-field.input',
  submitSelector: '.CvoPayment-submit.button',
  cardPlaceholder: '0000 0000 0000 0000',
  cvvPlaceHolder: '012',
  merchant: 'vpass',
  spreedlyElementsIds: spreedlyFormIds,
  paymentElementsIds: paymentFormIds,
};

const props = {
  spreedlyValidationError,
  spreedlyValidationMessage,
  cardNumberLengthEvent: null,
  cvvNumberLengthEvent: null,
  cvvNumberValidEvent: false,
  cardType: null,
  cardTypes: ['visa', 'master', 'american_express'],
  spreedlyValidationOK,
  merchant: config.merchant,
  authError: false,
};

const transactionType = {
  charge: 'CHARGE',
  void: 'VOID',
  authorisation: 'AUTHORISATION',
  authentication: 'AUTHENTICATION'
};

// STATES ARE CHANGING ??
const authState = {
  authenticated: 'AUTHENTICATED',
  succeeded: 'SUCCEEDED',
  pending: 'PENDING',
  failed: 'FAILED',
};

const paymentFlow = window.CVO?.flow3DS || '';
const with3DSFlow = 'authentication';

class PagePayment {

  static init() {
    PagePayment._initPaymentForm();

    PagePayment._initCountrySelect();

    PagePayment._initCopyBillingInfo();

    PagePayment._initAgreeOnPayment();

    PagePayment._initSpreedlyFields();

    /**
     * TODO: For some reason $currentLanguage is not set, 
     * so we need to get it from the current selected option in language dropdown.
     * This needs to be set by the controller
     */
    const selectLocale = document.getElementById('selectLanguage');
    const locale = selectLocale.options[selectLocale.selectedIndex].getAttribute('data-locale');
    window.CVO.currentLanguage = locale;
  }

  static _initPaymentForm() {
    this.formValidator = new FormValidator({
      formId: PAYMENT_FORM_ID,
      validateOnInput: false,
      submitId: PAYMENT_SUBMIT_ID,
      shouldSubmit: false,
      submitCallback: this._submitForm.bind(this)
    });
    this.formValidator.init();
  }

  static _initCountrySelect() {
    const countrySelect = new CountrySelect({
      countries: '#billingCountry',
      states: '#billingState',
      baseUrl: document.location.origin + '/y4/states/',
      required: true,
      disabled: true,
      notEmptyClass: 'required'
    });
    countrySelect.init();
  }

  static _initAgreeOnPayment() {
    paymentCommons.initAgreeOnPayment({
      formId: `#${PAYMENT_FORM_ID}`,
      checkbox: '.field-termsconditions input',
      paymentSubmitId: `#${PAYMENT_SUBMIT_ID}`
    });
  }

  static _initCopyBillingInfo() {
    const paymentCopyBillingInfo = new FormCopier({
      triggerSelector: '#useAccountData',
      validatorCheckbox: 'paymentAddress',
      fromId: 'user-on-register',
      toId: PAYMENT_FORM_ID
    });
    paymentCopyBillingInfo.init();
  }

  static _initSpreedlyFields() {
    // Set Spreedly Token and beging tokenization
    console.log('flow3DS: ', window.CVO.flow3DS);
    config.environment = window.CVO.spreedlyToken;

    spreedlyValidationHandler = new SpreedlyValidationsHandler(props);
    spreedlyValidationHandler.initSpreedlyFieldsValidations();

    spreedlyHandler = new SpreedlyHandler(config);
    spreedlyHandler.init(false);

    Spreedly.on('ready', function () {
      Spreedly.transferFocus('number');

      Spreedly.setStyle('number', 'width: 100%;');
      Spreedly.setStyle('cvv', 'width: 100%;');
    });

    // Update data on event change
    Spreedly.on('fieldEvent', function (name, type) {
      if (type == 'input' || type == 'blur') {
        spreedlyValidationError = spreedlyValidationHandler.validationStore.data.validationError;
        spreedlyValidationMessage = spreedlyValidationHandler.validationStore.data.validationMessage;
        spreedlyValidationOK = spreedlyValidationHandler.validationStore.data.validationOK;
      }
    });
  }

  static async _submitForm(formData) {
    const submitBtn = document.getElementById(PAYMENT_SUBMIT_ID);
    submitBtn.setAttribute('disabled', true);

    // TODO: add loader and hide/reset previous errors
    const paymentTokenizedData = await this._initSpreedlyPayment();
    if (paymentTokenizedData) {
      formData.set('payment-method-token', paymentTokenizedData.token);
      // serialize data
      const serializedFormData = {};
      for (let pair of formData.entries()) {
        serializedFormData[pair[0]] = pair[1];
      }

      const fullFormData = { ...serializedFormData, ...paymentTokenizedData };
      window.CVO.submitForm(window.CVO.paymentAction, fullFormData);
    } else {
      // Spreedly validation didn't pass we need to enable again
      submitBtn.removeAttribute('disabled');
    }
  }

  static async _initSpreedlyPayment() {
    spreedlyValidationHandler.validateSpreedlyForm();
    if (spreedlyValidationHandler.validationStore.data.validationOK) {
      try {
        // Tokenized data
        const tokenizedData = await spreedlyHandler.submit();
        // Map the response
        tokenizedPayload = this._mapTokenizedData(tokenizedData);
        let response;

        const riskAssessmentData = this._mapRiskAssessmentData(tokenizedData);
        const riskRecommendation = await this._submitRiskAssessmentRequest(riskAssessmentData);

        if(riskRecommendation?.data?.recommendation === 'REJECT') {
          throw Error('Risk assessment rejected');
        }


        // Toggle 3DS Flow | Vpass
        if (this._is3DSFlow(tokenizedPayload.authorizationData.cardType)) {
          response = await this._submitTokenizedPayload(tokenizedPayload.authenticationData);
        } else {
          response = await this._submitTokenizedPayload({
            ...tokenizedPayload.authorizationData,
            type: transactionType.authorisation,
            amount: window.CVO.authAmount,
            currency: window.CVO.currency,
          });
        }

        return this._processAuthResponse(response && response.data || null);
      } catch (e) {
        this._handleSpreedlyErrors(e);
      }
    }
    return null;
  }

  /**
   * Maps the tokenized response according to the backend needs
   */
  static _mapTokenizedData(spreedlyResponse) {
    const type = window.CVO.transactionType;
    const amount = window.CVO.transactionType === transactionType.authentication
      ? window.CVO.amount
      : window.CVO.authAmount;
    const currency = window.CVO.currency;
    const acceptHeader = 'text/html,application/xhtml+xml;q=0.9,*/*;q=0.8';

    // Required Authentication Data
    const authenticationData = {
      type,
      paymentToken: spreedlyResponse.spreedlyToken,
      paymentGateway: 'spreedly',
      gatewayType: 'cybersource',
      merchantId: config.merchant,
      amount,
      currency,
      browserInfo: {
        clientBrowserInfo: spreedlyHandler.captureBrowser(acceptHeader),
      }
    };


    // Required Authorization Data
    const authorizationData = {
      paymentToken: spreedlyResponse.spreedlyToken,
      customerId: spreedlyResponse.data.email,
      cardType: spreedlyResponse.data.card_type,
      expMonth: spreedlyResponse.data.month,
      expYear: spreedlyResponse.data.year,
      last4: spreedlyResponse.data.last_four_digits,
      countryCode: spreedlyResponse.data.country,
      country: spreedlyResponse.data.country,
      address: spreedlyResponse.data.address1,
      postCode: spreedlyResponse.data.zip,
    };

    // Add the start date for pass creation
    const startElement = document.querySelector('input[name="start"]:checked');
    if (startElement) {
      authorizationData['start'] = startElement.value;
    }

    return { authenticationData, authorizationData };
  }

  /**
   * Maps the tokenized response according to the backend needs
   */
  static _mapRiskAssessmentData(spreedlyResponse)
  {
    const type = window.CVO.transactionType;
    const amount = window.CVO.transactionType === transactionType.authentication
        ? window.CVO.amount
        : window.CVO.authAmount;
    const currency = window.CVO.currency;
    const acceptHeader = 'text/html,application/xhtml+xml;q=0.9,*/*;q=0.8';

    // Required Authentication Data
    return {
      provider: 'accertify',
      token: spreedlyResponse.spreedlyToken,
      transactionType: type,
      amount,
      currency,
      planStartDate: Date.now(),
      product: 'vpass',
      browserInfo: {
        clientBrowserInfo: spreedlyHandler.captureBrowser(acceptHeader),
      },
      user: {
        id: spreedlyResponse.data.email,
        email: spreedlyResponse.data.email,
        name: `${spreedlyResponse.data.first_name} ${spreedlyResponse.data.last_name}`,
        phone: spreedlyResponse.data.phone_number,
        pnr: '',
      }
    };

  }

  /**
   * Sends the tokenized data for authentication
   */
  static async _submitTokenizedPayload(authenticationData) {
    try {
      const response = await Axios.post(window.CVO.paymentsOrchestratorResource, authenticationData);
      return response;
    } catch (e) {
      spreedlyValidationHandler.validationStore.data.authError = true;
      console.error('Error: ', e);
      return null;
    }
  }

  /**
   * Performs the corresponding action based on the authorization/authentication response
   */
  static async _processAuthResponse(response) {
    const data = tokenizedPayload.authorizationData;

    if (response === null) {
      this._onFailed(response);
    } else if (response.state === authState.failed) {
      this._onFailed(response);
    } else if (response.state === authState.succeeded) {
      if (this._is3DSFlow(data.cardType)) {
        // Process 3DS flow for cards with frictionless (state is immediately succeeded without any challenge).
        // In this case the backend returns the sca authentication token in the response.id attribute.
        const authorization = await this._processAuthorizationRequest(data, response.id);
        if (authorization.data.state === authState.succeeded) {
          return this._onAuthSuccess(authorization.data);
        } else {
          this._onFailed(authorization);
        }
      } else {
        // Process No 3DS Flow.
        return this._onAuthSuccess(response);
      }
    } else if (response.state === authState.pending) {
      // Process 3DS Flow for cards without frictionless (state is pending until the challenge is completed).
      return this._onAuthPending(response, data);
    }
  }

  static async _processAuthorizationRequest(authorizationData, token) {
    const type = transactionType.authorisation;
    const amount = window.CVO.transactionType !== transactionType.authorisation
      ? window.CVO.authAmount
      : window.CVO.amount;
    const currency = window.CVO.currency;

    const data = {
      paymentGateway: 'spreedly',
      gatewayType: 'cybersource',
      paymentToken: token,
      type,
      merchantId: config.merchant,
      status: authState.authenticated,
      amount,
      currency,
      customerId: authorizationData.customerId,
    };

    try {
      return await Axios.post(window.CVO.paymentsOrchestratorResource, data);
    } catch (error) {
      spreedlyValidationHandler.validationStore.data.authError = true;
      this._handleSpreedlyErrors(error);
      console.error('processAuthorizationRequest: ', error);
    }
  }

  /**
   * Method executed when authorization/authentication failed
   */
  static _onFailed(statusEvent) {
    spreedlyValidationHandler.validationStore.data.authError = true;
    // this._toggleTokenizationError(true);
    this._handleSpreedlyErrors(statusEvent);
    console.error('statusEvent: ', statusEvent);
  }

  /**
   * Method executed when authorization/authentication passes and does not require 3ds
   */
  static async _onAuthSuccess(statusEvent) {
    const type = transactionType.void;

    try {
      const data = {
        paymentGateway: 'spreedly',
        gatewayType: 'cybersource',
        type,
        id: statusEvent.id,
        currency: statusEvent.amount.currency,
        amount: statusEvent.amount.qty,
      };

      await this._cancelAuthTransaction(data);
      return this._getFormPayload();
    } catch (error) {
      spreedlyValidationHandler.validationStore.data.authError = true;
      this._handleSpreedlyErrors(error);
      console.error('Error: ', error);
    }
  }

  static async _cancelAuthTransaction(data) {
    try {
      const response = await Axios.post(window.CVO.paymentsOrchestratorResource, data);
      return response;
    } catch (error) {
      spreedlyValidationHandler.validationStore.data.authError = true;
      this._handleSpreedlyErrors(error);
      console.error('Error: ', error);
    }
  }

  static async _onAuthPending(response, data) {
    try {
      const lifeCycleResult = await new Promise((resolve, reject) => {
        const spreedlyLifecycle = spreedlyHandler.createLifecycle(
          response.id,
          resolve,
          reject,
          reject
        );

        spreedlyLifecycle.start();
      });

      if (lifeCycleResult) {
        // In this case the backend returns the sca authentication token in the response.id attribute.
        return this._onChallengeSuccess(data, response.id);
      }
    } catch (statusEvent) {
      this._onChallengeError(statusEvent);
    }
    return null;
  }

  static _onChallengeError(statusEvent) {
    this._handleSpreedlyErrors(statusEvent);
    console.error('statusEvent: ', statusEvent);
  }

  static _onChallengeTimeout(statusEvent) {
    this._handleSpreedlyErrors(statusEvent);
    console.error('statusEvent: ', statusEvent);
  }

  static async _onChallengeSuccess(data, token) {
    try {
      const response = await this._processAuthorizationRequest(data, token);
      if (response.data.state === authState.succeeded) {
        return this._onAuthSuccess(response.data);
      } else {
        this._onFailed(response);
      }
    } catch (error) {
      this._handleSpreedlyErrors(error);
      console.error('Error: ', error);
    }
  }

  /**
   * Returns an object with the data to submit the form
   * this is the final step before finishing the payment process
   */
  static _getFormPayload() {
    const data = tokenizedPayload.authorizationData;
    return {
      token: data.paymentToken,
      start: data.start,
      customerId: data.customerId,
      last4: data.last4
    };
  }

  static _handleSpreedlyErrors(err) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const cardYear = document.getElementById(spreedlyFormIds.year).value;
    const cardMonth = document.getElementById(spreedlyFormIds.month).value;
    const cardDate = new Date(`${cardMonth}/1/${cardYear}`).getTime();
    const todayDate = new Date().getTime();

    // Spreedly does not handle card expiration properly.
    // Check locally the card data validity
    if (cardMonth && cardYear && (cardDate < todayDate)) {
      this._toggleErrorMessage(true, trans[window.CVO.currentLanguage].expired.number);
      spreedlyValidationHandler.validationStore.data.authError = false;
    } else {
      if (err && err instanceof Array) {
        for (let i = 0; i < err.length; i++) {
          const error = err[i];
          const errorKey = error.key.split('.').pop();

          console.error("Spreedly error:" + error.key)
          spreedlyValidationError = true;
          const errorValue = trans[window.CVO.currentLanguage][errorKey]
          if (typeof errorValue === 'object' && errorValue !== null) {
            spreedlyValidationMessage = errorValue[error.attribute];
          } else if (typeof errorValue === 'string') {
            spreedlyValidationMessage = errorValue;
          } else {
            spreedlyValidationMessage = "Error";
          }
          spreedlyValidationHandler.validationStore.data.authError = false;
          this._toggleErrorMessage(spreedlyValidationError, spreedlyValidationMessage);
        }
      } else {
        spreedlyValidationError = false;
        const hasAuthError = spreedlyValidationHandler.validationStore.data.authError = true;
        this._toggleErrorMessage(spreedlyValidationError);
        this._toggleTokenizationError(hasAuthError);
      }
    }
  }

  static checkMonthInput(event) {
    event.target.value = event.target.value
      .replace(/^([1-9]\/|[2-9])$/g, '0$1')
      .replace(/[^\d]$/g, '');
  }

  static checkYearInput(event) {
    event.target.value = event.target.value
      .replace(/^([1-9]\/|[2-9])$/g, '$1')
      .replace(/[^\d]$/g, '');
  }

  static _toggleTokenizationError(toggle) {
    const authErrorMessage = document.getElementsByClassName('CvoForm-paymentAuthorizationError')[0];
    authErrorMessage.style.display = toggle ? 'block' : 'none';
  }

  static _toggleErrorMessage(toggle, messageText = '') {
    const erroMessage = document.querySelector('#validationNotificationError');
    erroMessage.textContent = messageText;
    erroMessage.style.display = toggle ? 'block' : 'none';
  }

  static _is3DSFlow(cardType) {
    return this._is3DSSupported(cardType) && paymentFlow === with3DSFlow;
  }

  static _is3DSSupported(cardType) {
    return cardType !== 'american_express'; // We don't support 3DS for amex cards.
  }

  static async _submitRiskAssessmentRequest(riskAssessmentData) {
    try {
      return await Axios.post(window.CVO.riskAssessmentAction, riskAssessmentData);
    } catch (e) {
      return null;
    }
  }
}

// Page initialization
window.addEventListener('DOMContentLoaded', () => {
  if (paymentForm) {
    PagePayment.init();

    const monthField = document.getElementById('spreedly-month');
    const yearField = document.getElementById('spreedly-year');
    monthField.addEventListener('keyup', (e) => {
      PagePayment.checkMonthInput(e);
    }, false);
    yearField.addEventListener('keyup', (e) => {
      PagePayment.checkYearInput(e);
    }, false);
  }
});
