import EnableOnAgreement from './form-enableOnAgreement';

/**
 * This function init an object that controls the checkboxes of agreeing to terms for a form
 * @param {Object} options object with options for the validation
 *  -formId (string): Identification of the form to locate in the DOM
 *  -checkbox (string): Identification of the checkboxes that must check their state
 *  -paymentSubmitId (string): Identification of the submit button to locate in the DOM
 */
function initAgreeOnPayment(options) {
  const { formId, checkbox, paymentSubmitId } = options;

  return new EnableOnAgreement({
    id: formId,
    checkbox: checkbox,
    anyCheckbox: false,
    submit: paymentSubmitId
  });
}

export default {
  initAgreeOnPayment
};
