const _allValid = Symbol('_allValid');
const _appendError = Symbol('_appendError');
const _getMessage = Symbol('_getMessage');
const _getMessageTranslation = Symbol('_getMessageTranslation');
const _onSubmit = Symbol('_onSubmit');
const _removeError = Symbol('_clearError');
const _resetField = Symbol('_resetField');
const _shouldValidate = Symbol('_shouldValidate');
const _showValidityAll = Symbol('_showValidityAll');
const _toggleSubmit = Symbol('_toggleSubmit');
const _validate = Symbol('_validate');
const _validateAll = Symbol('_validateAll');
const _validateOptions = Symbol('_validateOptions');

const VALID = 'valid';
const INVALID = 'invalid';
const SHOW_VALIDITY = 'show-validity';
const ATTR_VALIDATION_ERROR = 'data-validation-error';
const ATTR_VALIDATION_TYPE = 'data-validation-type';

/**
 * This form validation class heavily relies on browser's built-in form validation
 * mechanisms. Its purpose is indeed to just customize validation appearance, and
 * allow custom validation rules.
 *
 * Validation constraints on inputs are defined by using the standard HTML validation
 * attributes on form elements. Custom contraints are enforced by registering custom
 * validators, bond to any event (e.g. submit, input, blur, ...).
 *
 * Options:
 *   - `formId` (required): the id of the form to validate.
 *   - `validateOnInput`: if `true`, the validation will be performed immediately,
 *                        otherwise only on submit. Default: `false`.
 *   - `submitId`: the id of the submit button. When `validateOnInput` is `true`, the
 *                 button will be disabled if any field is invalid.
 *   - `customMessages`: an object with custom validation errors. [TODO: document format]
 *   - `shouldSubmit`: <boolean>. if false, prevents the form submit to be hanlded async using the `submitCallback`
 *   - `submitCallback`: <function>. called when `shouldSubmit` is false to handle form submission.
 */
class FormValidator {

  constructor(options) {
    this[_validateOptions](options);

    this.form = document.querySelector('#' + options.formId);

    this.shouldSubmit = 'shouldSubmit' in options ? options.shouldSubmit : true;

    this.submitCallback = 'submitCallback' in options ? options.submitCallback : null;

    this.validateOnInput = 'validateOnInput' in options ? options.validateOnInput : false;

    this.submit = 'submitId' in options
      ? document.querySelector('#' + options.submitId)
      : document.querySelector('input[type=submit]');

    this.messages = 'customMessages' in options ? options.customMessages : null;

    this.validators = new Map();
  }

  init() {
    // Disable built-in validation
    this.form.setAttribute('novalidate', '');

    // Disable submit button when validating on-the-fly
    if (this.validateOnInput && this.submit) {
      this.submit.setAttribute('disabled', '');
    }

    // Add missing placeholders (they are needed to validate only actually modified fields)
    this.form.querySelectorAll('input').forEach((el) => {
      if (!el.hasAttribute('placeholder')) {
        el.setAttribute('placeholder', ' ');
      }
    });

    // Event listeners. `invalid` event is not used because it doesn't bubbles outside the invalid
    // element, making impossible to handle it when delegating.
    this.form.addEventListener('input', (evt) => {
      if (!this[_shouldValidate](evt.target)) {
        return true;
      }
      this[_validate](evt.target);
    });

    this.form.addEventListener('submit', (evt) => { this[_onSubmit](evt); });

    if (this.validateOnInput) {
      this.form.addEventListener('input', (evt) => {
        if (!this[_shouldValidate](evt.target)) {
          return true;
        }
        this.toggleValidity(evt.target, true);
      });

    } else {
      this.form.addEventListener('input', (evt) => {
        if (!this[_shouldValidate](evt.target)) {
          return true;
        }
        this[_resetField](evt.target);
      });
    }
  }

  registerCustomValidator(name, customValidator) {
    if (typeof customValidator.validate !== 'function') {
      throw new Error('Cannot register custom validator: interface requirements not fulfilled');
    }
    customValidator.validator = this;
    this.validators.set(name, customValidator);
  }

  clearStatus(selectorOrElement) {
    if (typeof selectorOrElement === 'string') {
      document.querySelectorAll(selectorOrElement).forEach((el) => {
        this[_resetField](el);
      });

    } else if (selectorOrElement instanceof HTMLElement) {
      this[_resetField](selectorOrElement);

    } else {
      console.warn('clearStatus invoken on unsupported object type');
    }
  }

  markValid(el, resetCustomError = false) {
    if (resetCustomError && el.setCustomValidity) {
      el.setCustomValidity('');
    }

    el.classList.add(VALID);
    el.classList.remove(INVALID);
    this[_removeError](el);
  }

  markInvalid(el, customError = null) {
    if (customError && el.setCustomValidity) {
      el.setCustomValidity(customError);
    }

    el.classList.remove(VALID);
    el.classList.add(INVALID);
    this[_appendError](el);
  }

  toggleValidity(el, show) {
    // FIXME: improve this voodoo with children
    if (show) {
      el.classList.add(SHOW_VALIDITY);
      for (let index = 0; index < el.parentElement.children.length; index++) {
        const c = el.parentElement.children[index];
        if (c.classList.contains('input-invalid-message')) {
          c.classList.add(SHOW_VALIDITY);
        }
      }

    } else {
      el.classList.remove(SHOW_VALIDITY);
      for (let index = 0; index < el.parentElement.children.length; index++) {
        const c = el.parentElement.children[index];
        if (c.classList.contains('input-invalid-message')) {
          c.classList.remove(SHOW_VALIDITY);
        }
      }
    }
  }

  validateForm() {
    this[_validateAll]();
    this[_showValidityAll]();
  }

  [_validateOptions](options) {
    if (!('formId' in options) || !document.querySelector('#' + options.formId)) {
      throw new Error('Cannot initialize FromValidation: missing formId');
    }
  }

  [_validate](el) {
    if (!this[_shouldValidate](el)) {
      return true;
    }

    const customValid = (() => {
      if (!el.hasAttribute('data-validator')) {
        return true;
      }
      const customValidator = this.validators.get(el.getAttribute('data-validator'));
      if (customValidator) {
        return customValidator.validate(el);
      } else {
        console.error(`Custom validator ${customValidator} not registered! Not validating the field.`);
        return true;
      }
    })();

    // Check with both custom and default validation
    // `el.reportValidity()` would correctly report validity only for elements with `el.willValidate === true`
    const valid = customValid && el.validity.valid;

    if (valid) {
      this.markValid(el);
    } else {
      this.markInvalid(el);
    }

    if (this.validateOnInput && this[_allValid]()) {
      this[_toggleSubmit](customValid);
    }
  }

  [_onSubmit](evt) {
    evt.preventDefault();

    // Trigger validations. Standard ones are already performed by the browser, but still
    // validation classes must be set
    this[_validateAll]();
    if (this[_allValid]()) {
      if (this.shouldSubmit) {
        this.form.submit();
      } else {
        console.log('Validation passed but submit disabled');
        const data = new FormData(this.form);
        typeof this.submitCallback === 'function' && this.submitCallback(data);
      }
    } else {
      // Prevents any other action on submit
      evt.stopImmediatePropagation();

      this[_showValidityAll]();
    }
  }

  [_allValid]() {
    return this.form.querySelectorAll(`.${INVALID}`).length === 0;
  }

  [_validateAll]() {
    // Trigger validations. Standard ones are already performed by the browser, but still
    // validation classes must be set
    this.form.querySelectorAll('input, select, textarea, *[data-validator]').forEach((el) => {
      this[_validate](el);
    });
  }

  [_showValidityAll]() {
    this.form.querySelectorAll(`.${INVALID}`).forEach((el) => {
      this[_appendError](el);
      this.toggleValidity(el, true);
    });
    this.form.querySelectorAll(`.${VALID}`).forEach((el) => {
      this[_removeError](el);
      this.toggleValidity(el, true);
    });
  }

  [_appendError](element) {
    const msgText = this[_getMessage](element);
    if (!msgText) {
      return;
    }

    let msgElement = element.parentNode.querySelector('span.input-invalid-message');
    // Check if exists
    if (!msgElement) {
      msgElement = document.createElement('span');
      var errorContainer = element.parentNode;
      if (errorContainer.tagName === 'LABEL') {
        // Ugly hack to workaround input-inside-label scenario
        errorContainer = errorContainer.parentNode;
      }
      errorContainer.appendChild(msgElement);
    }
    msgElement.classList.add('input-invalid-message');
    msgElement.innerText = msgText;
  }

  [_removeError](element) {
    let parentNode = element.parentNode;
    if (parentNode.tagName === 'LABEL') {
      // Ugly hack to overcome input-inside-label scenario
      parentNode = parentNode.parentNode;
    }
    const msgElement = parentNode.querySelector('span.input-invalid-message');
    if (msgElement) {
      msgElement.remove();
    }
  }

  [_getMessage](element) {
    let errorType;
    if (element.hasAttribute(ATTR_VALIDATION_ERROR)) {
      errorType = element.getAttribute(ATTR_VALIDATION_ERROR);

    } else if (element.validity.customError) {
      // Custom error have precedence
      errorType = 'customError';

    } else {
      for (const t in element.validity) {
        if (element.validity[t]) {
          errorType = t;
          break;
        }
      }
    }

    const params = new Map();
    switch (errorType) {
      case 'tooShort':
        params.set('minlength', element.minLength);
        params.set('currentLength', element.value.length);
        break;
      default:
        break;
    }

    return this[_getMessageTranslation](errorType, element, params);
  }

  [_getMessageTranslation](errorType, element, params = new Map()) {
    let msg;
    let msgVariant;
    const { validationMessages } = window.Cvo;

    if (element.getAttribute(ATTR_VALIDATION_TYPE)) {
      msgVariant = element.getAttribute(ATTR_VALIDATION_TYPE);

    } else if (element.getAttribute('type')) {
      msgVariant = element.getAttribute('type');

    } else {
      msgVariant = 'default';
    }

    /**
     * Check the validation messages on 'form-validation-strings.blade.php'
     */
    if (errorType in validationMessages) {
      if (msgVariant in validationMessages[errorType]) {
        msg = validationMessages[errorType][msgVariant];

      } else if ('default' in validationMessages[errorType]) {
        msg = validationMessages[errorType]['default'];
      }
    }

    params.forEach((value, key) => {
      const re = new RegExp(`\\$${key}`, 'gi');
      msg = msg.replace(re, value);
    });
    return msg;
  }

  [_resetField](el) {
    el.classList.remove(VALID);
    el.classList.remove(INVALID);
    el.removeAttribute(ATTR_VALIDATION_ERROR);
    el.removeAttribute(ATTR_VALIDATION_TYPE);
    if (el.setCustomValidity) {
      el.setCustomValidity('');
    }
    this.toggleValidity(el, false);
    this[_removeError](el);
  }

  [_toggleSubmit](enabled) {
    if (enabled) {
      this.submit.removeAttribute('disabled');
    } else {
      this.submit.setAttribute('disabled', '');
    }
  }

  [_shouldValidate](el) {
    // Half-hack to allow fieldset validation
    if (el.willValidate || (el.tagName.toLowerCase() === 'fieldset')) {
      return true;

    } else {
      return false;
    }
  }
}

export {
  FormValidator,
  VALID, INVALID,
  ATTR_VALIDATION_ERROR,
  ATTR_VALIDATION_TYPE
};
