/**
 * Class used for vclub and might be reused by vpass
 */

const DEFAULTS = {
  cardPlaceholder: '',
  cvvPlaceHolder: '',
  inputSelector: 'input'
};

class SpreedlyHandler {
  /**
   *  @param {
   *    environment: string,
   *    spreedlyElementsIds: {
   *      token: string,
   *      card: string,
   *      month: string,
   *      year: string,
   *      cvv: string,
   *      name: string
   *    }
   *    paymentElementsIds: {
   *      email: string,
   *      phoneNumber: 'string,
   *      address: string,
   *      postCode: string,
   *      country: string,
   *      provinceState: string,
   *      city: string
  *     }
   *    inputSelector: string,
   *    submitSelector: string,
   *    cardPlaceholder: string,
   *    cvvPlaceholder: string
   * } config Config object
   */
  constructor(config) {
    try {
      if(!config.environment || !config.spreedlyElementsIds || !config.paymentElementsIds) {
        this.config = null;
        throw 'Missing config properties. Make sure to include "environment", "spreedlyElementsIds"  and "paymentElementsIds"';
      }

      this.config = {...DEFAULTS, ...config};

      this._resolvePromise = null;
      this._rejectPromise = null;
      this._lifecycle = null;
      this._isLifeCycleStarted = false;
      
    } catch(e) {
      console.error(e);
    }
  }

  /**
   * Sets up the spreedly iframe
   */
  init(asyncEnvironment = true) {
    Spreedly.init(this.config.environment, {
      numberEl: this.config.spreedlyElementsIds.card,
      cvvEl: this.config.spreedlyElementsIds.cvv
    });
    
    this.customizeIframe();

    // set up listeners
    this.asyncEnvironment = asyncEnvironment;
    if (asyncEnvironment) {
      this.onError();
      this.onTokenResponse();
    }
  }

  /**
   * Handles the token process
   * @returns {Promise} token promise
   */
  async submit(userData) {
    if (!this.asyncEnvironment) {
      // Guarantee spreedly events are properly handled outside vue apps
      return new Promise((resolve, reject) => {
        Spreedly.on('errors', reject);
        Spreedly.on('paymentMethod', (token, pmData) => {
          // Set the token in the hidden form field
          var tokenField = document.getElementById(this.config.spreedlyElementsIds.token);
          tokenField.setAttribute('value', token);

          resolve({spreedlyToken: token, data: pmData});
        });
        this.tokenizePaymentForm(userData);
      });
    }
    return new Promise((resolve, reject) => {
      this._resolvePromise = resolve;
      this._rejectPromise = reject;
      this.tokenizePaymentForm(userData);
    });
  }

  /**
   * Sends the credit card data to be tokenized
   */
  tokenizePaymentForm(userData) {
    var requiredFields = {};

    if (userData) {
      requiredFields = userData;
    }
    else {
      // Get required, non-sensitive, values from host page
      // FIXME Currently sending the 'full_name' field as it's the one requested with the card details, and sending also 
      // first_name + last_name could cause issues
      // requiredFields['first_name'] = document.getElementById(this.config.paymentElementsIds.firstName).value;
      // requiredFields['last_name'] = document.getElementById(this.config.paymentElementsIds.lastName).value;
      requiredFields['email'] = document.getElementById(this.config.paymentElementsIds.email).value;
      requiredFields['phone_number'] = document.getElementById(this.config.paymentElementsIds.phoneNumber).value;
      requiredFields['address1'] = document.getElementById(this.config.paymentElementsIds.address).value;
      requiredFields['zip'] = document.getElementById(this.config.paymentElementsIds.postCode).value;
      requiredFields['country'] = document.getElementById(this.config.paymentElementsIds.country).value;
      requiredFields['state'] = document.getElementById(this.config.paymentElementsIds.provinceState).value;
      requiredFields['city'] = document.getElementById(this.config.paymentElementsIds.city).value;
    }
    
    requiredFields['full_name'] = document.getElementById(this.config.spreedlyElementsIds.name).value;
    requiredFields['month'] = document.getElementById(this.config.spreedlyElementsIds.month).value;
    requiredFields['year'] = document.getElementById(this.config.spreedlyElementsIds.year).value;
    if (true === this.config.requireCvv) {
      requiredFields['cvv'] = document.getElementById(this.config.spreedlyElementsIds.cvv).value;
    }

    Spreedly.tokenizeCreditCard(requiredFields);
  }

  /**
   * Listens to spreedly error and rejects the promise
   */
  onError() {
    Spreedly.on('errors', (errors) => {
      this._rejectPromise && this._rejectPromise(errors);
    });
  }

  /**
   * Listens to spreedly success tokenization and resolves the promise
   */
  onTokenResponse() {
    Spreedly.on('paymentMethod', (token, pmData) => {

      // Set the token in the hidden form field
      var tokenField = document.getElementById(this.config.spreedlyElementsIds.token);
      tokenField.setAttribute('value', token);

      this._resolvePromise && this._resolvePromise({spreedlyToken: token, data: pmData});
    });
  }

  /**
   * Capture browser data by using `Spreedly.ThreeDS.serialize().
   * @param {string} acceptHeader
   * @returns string
   */
  captureBrowser(acceptHeader) {
    // Choose browser size for the application.
    // This will be the size of the challenge iframe that will be presented to a user.

    // If creating a modal, make the surrounding DOM node a little larger than the option below.

    // '01' - 250px x 400px
    // '02' - 390px x 300px
    // '03' - 500px x 600px
    // '04' - 600px x 400px
    // '05' - fullscreen
    const browserSize = '04';

    return Spreedly.ThreeDS.serialize(
      browserSize,
      acceptHeader
    );
  }
 
  /**
   * Creates and starts the lifecycle
   * @param {string} transactionToken 
   * @param {function} callback function to be executed on the 3ds status change
   * @returns lifecycle
   */
  createLifecycle(transactionToken, successCallback, errorCallback, timeoutCallback) {
    if (!this._lifecycle) {
      // Setup event handling and kickoff 3DSecure lifecycle
      Spreedly.on('3ds:status', (threeDsStatusEvent) => {
        this.handle3DSstatusUpdate(threeDsStatusEvent, successCallback, errorCallback, timeoutCallback);
      });  
    }
    
    this._lifecycle = new Spreedly.ThreeDS.Lifecycle({
      environmentKey: this.config.environment,
      // The DOM node (id) that you'd like to inject hidden iframes
      hiddenIframeLocation: 'device-fingerprint',
    
      // The DOM node (id) that you'd like to inject the challenge flow
      challengeIframeLocation: 'challenge',
    
      // The token for the transaction - used to poll for state
      transactionToken: transactionToken,
    
      // The css classes that you'd like to apply to the challenge iframe.
      // e.g. 'red-border left-positioned custom-styles'
      challengeIframeClasses: 'spreedly-challenge-iframe' // (optional)
    });

    return this._lifecycle;
  }

  startLifecycle() {
    this._lifecycle.start();
  }

  handle3DSstatusUpdate(threeDsStatusEvent, successCallback, errorCallback, timeoutCallback) {
    document.querySelector('body').style.overflow = '';
    document.getElementById('challenge-modal').classList.add('hidden');
    if (threeDsStatusEvent.action === 'succeeded') {
      successCallback(threeDsStatusEvent);
    } else if (threeDsStatusEvent.action === 'error') {
      // Spreedly won't reuse or discard the previous used iframe
      const oldIframe = document.querySelector('.spreedly-challenge-iframe');
      oldIframe && oldIframe.parentElement.removeChild(oldIframe);
      errorCallback(threeDsStatusEvent);
    } else if (threeDsStatusEvent.action === 'finalization-timeout') {
      // Spreedly won't reuse or discard the previous used iframe
      const oldIframe = document.querySelector('.spreedly-challenge-iframe');
      oldIframe && oldIframe.parentElement.removeChild(oldIframe);
      timeoutCallback(threeDsStatusEvent);
    } else if (threeDsStatusEvent.action === 'challenge') {
      // show the challenge-modal
      document.querySelector('body').style.overflow = 'hidden';
      document.getElementById('challenge-modal').classList.remove('hidden');
    }
  }

  customizeIframe() {
    // disable submit until iframes are loaded
    const submitButton = document.querySelector(this.config.submitSelector);
    submitButton && submitButton.setAttribute('disabled', 'disabled');
  
    const cssStyles = this.getInputStyles();

    Spreedly.on('ready', () => {
      submitButton && submitButton.removeAttribute('disabled');
      Spreedly.setFieldType('number', 'text');
      Spreedly.setNumberFormat('prettyFormat');
      Spreedly.setPlaceholder('number', this.config.cardPlaceholder);
      Spreedly.setPlaceholder('cvv', this.config.cvvPlaceHolder);

      Spreedly.setStyle('number', cssStyles);
      Spreedly.setStyle('cvv', cssStyles);
    });
  }

  getInputStyles() {
    let cssText = '';
    if(this.config.inputSelector) {
      const input = document.querySelector('.CvoForm-field.input');
      const styles = getComputedStyle(input);
      
      cssText = Array.from(styles).reduce((str, property) => {
        return `${str}${property}:${styles.getPropertyValue(property)};`;
      }, '');
    }
    return cssText;
  }
}

export default SpreedlyHandler;