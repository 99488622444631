/* Spreedly Validation Fields Helper for VClub & VPass */
/* This helper is set up inside & oustside Vue App */
/* README: For simplicity & urgency sake, only CVV and Card Number fields are being highlighted
 * when errors occur. Month, Year and Cardholder field highlights will be handled later for better UX
 */
import Vue from 'vue';
import trans from './locale/translation';

const vplusApp = 'vplus';
const vclubApp = 'vclub';
const vpassApp = 'vpass';

class SpreedlyValidationsHandler {
  constructor(props) {
    if (props.merchant === vclubApp || props.merchant === vplusApp) {
      // Vue Props | Vclub
      this.validationStore = Vue.observable({
        data: {
          validationError: props.spreedlyValidationError,
          validationMessage: props.spreedlyValidationMessage,
          cardNumberLengthEvent: props.cardNumberLengthEvent,
          cvvNumberLengthEvent: props.cvvNumberLengthEvent,
          cvvNumberValidEvent: props.cvvNumberValidEvent,
          cardType: props.cardType,
          cardTypes: props.cardTypes,
          validationOK: props.spreedlyValidationOK,
          merchant: props.merchant,
          authError: props.authError,
        }
      });
    }

    if (props.merchant === vpassApp) {
       // JS Props | Vpass
      this.validationStore = {
        data: {
          validationError: props.spreedlyValidationError,
          validationMessage: props.spreedlyValidationMessage,
          cardNumberLengthEvent: props.cardNumberLengthEvent,
          cvvNumberLengthEvent: props.cvvNumberLengthEvent,
          cvvNumberValidEvent: props.cvvNumberValidEvent,
          cardType: props.cardType,
          cardTypes: props.cardTypes,
          validationOK: props.spreedlyValidationOK,
          merchant: props.merchant,
          authError: props.authError,
        }
      };
    }
  }

  initSpreedlyFieldsValidations() {
    const self = this;

    Spreedly.on('fieldEvent', function(name, type, activeEl, inputProperties) {
      // Check Card Number is provided
      if(name == 'number') {
        if (type == 'focus') Spreedly.setStyle('number', 'border-color: #a12885');
        
        if (type == 'input') {
          if (!inputProperties['numberLength']) {
            self.handleCardNumberField('number', true, 'red', trans[window.CVO.currentLanguage].blank.number);
            self.validationStore.data.cardNumberLengthEvent = null;
          } else {
            self.handleCardNumberField('number', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.number);
            self.validationStore.data.cardNumberLengthEvent = inputProperties['numberLength'];
            self.validationStore.data.cvvNumberLengthEvent = inputProperties['cvvLength'];
          }
          
          // Check if type of cards provided are acceptable
          if (self.validationStore.data.cardNumberLengthEvent && !self.validationStore.data.cardTypes.includes(inputProperties['cardType'])) {
            self.handleCardNumberField('number', true, 'red', trans[window.CVO.currentLanguage].card.type);
            self.validationStore.data.cardNumberLengthEvent = inputProperties['numberLength'];
          } else {
            // Do CVV validation when card number is being provided
            if (self.validationStore.data.cvvNumberLengthEvent && inputProperties['validCvv']) {
              self.handleCvvNumberField('cvv', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.cvv);
              self.validationStore.data.cvvNumberValidEvent = inputProperties['validCvv'];
            }
            self.handleCardNumberField('number', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.number);
            self.validationStore.data.cardNumberLengthEvent = inputProperties['numberLength'];

          }
          self.validationStore.data.cardType = inputProperties['cardType'];
        }

        if (type == 'blur') {
          if (!self.validationStore.data.cardNumberLengthEvent)
            self.handleCardNumberField('number', true, 'red', trans[window.CVO.currentLanguage].blank.number);
          else if (self.validationStore.data.cardNumberLengthEvent && !self.validationStore.data.cardTypes.includes(self.validationStore.data.cardType))
            self.handleCardNumberField('number', true, 'red', trans[window.CVO.currentLanguage].card.type);
          else
            self.handleCardNumberField('number', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.number);
        }
      }

      // Check CVV Number is provided. This depends on Card Number provided
      if(name == 'cvv') {
        if (type == 'focus') Spreedly.setStyle('cvv', 'border-color: #a12885');

        if (type == 'input') {
          if (self.validationStore.data.cardNumberLengthEvent) { 
            if (!inputProperties['cvvLength']) {
                self.handleCvvNumberField('cvv', true, 'red', trans[window.CVO.currentLanguage].blank.cvv);
                self.validationStore.data.cvvNumberLengthEvent = null;
            } else {
                self.handleCvvNumberField('cvv', false, 'red', trans[window.CVO.currentLanguage].checked.cvv);
                self.validationStore.data.cvvNumberLengthEvent = inputProperties['cvvLength'];
            }

            // Check if CVV number is valid according to the type of card provided
            if (self.validationStore.data.cvvNumberLengthEvent && !inputProperties['validCvv']) {
                self.handleCvvNumberField('cvv', true, 'red', trans[window.CVO.currentLanguage].invalid.cvv);
            } else {
                self.handleCvvNumberField('cvv', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.cvv);
            }
            self.validationStore.data.cvvNumberValidEvent = inputProperties['validCvv'];
          }
        }

        if (type == 'blur') {
          if (self.validationStore.data.cardNumberLengthEvent && self.validationStore.data.cardTypes.includes(inputProperties['cardType'])) {
            if (self.validationStore.data.cvvNumberLengthEvent && inputProperties['validCvv']) {
              self.handleCvvNumberField('cvv', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.cvv);
              self.validationStore.data.cvvNumberValidEvent = inputProperties['validCvv'];
              self.validationStore.data.cvvNumberLengthEvent = inputProperties['cvvLength'];
            }
          }

          if (!self.validationStore.data.cvvNumberLengthEvent)
            self.handleCvvNumberField('cvv', true, 'red', trans[window.CVO.currentLanguage].blank.cvv);
          else if (self.validationStore.data.cvvNumberLengthEvent && !self.validationStore.data.cvvNumberValidEvent)
            self.handleCvvNumberField('cvv', true, 'red', trans[window.CVO.currentLanguage].invalid.cvv);
          else
            self.handleCvvNumberField('cvv', false, '#e7e7e7', trans[window.CVO.currentLanguage].checked.cvv);
        }
      }
    });
  }

  // Spreedly defaulted CVV to not mandatory & blank card number is considered as invalid
  // Workaround to make the Spreedly card number & CVV fields mandotory
  validateSpreedlyForm() {
    this.validationStore.data.validationOK = false;

    if (!this.validationStore.data.cardNumberLengthEvent) {
      this.handleCardNumberField('number', true, 'red', trans[window.CVO.currentLanguage].blank.number);
    } else if (!this.validationStore.data.cvvNumberLengthEvent) {
      this.handleCvvNumberField('cvv', true, 'red', trans[window.CVO.currentLanguage].blank.cvv);
    } else if (this.validationStore.data.cardNumberLengthEvent
        && !this.validationStore.data.cardTypes.includes(this.validationStore.data.cardType)) {
      this.handleCardNumberField('number', true, 'red', trans[window.CVO.currentLanguage].card.type);
    } else if (this.validationStore.data.cvvNumberLengthEvent
      && !this.validationStore.data.cvvNumberValidEvent) {
      this.handleCvvNumberField('cvv', true, 'red', trans[window.CVO.currentLanguage].invalid.cvv);
    } else {
      this.validationStore.data.validationOK = true;
      this.validationStore.data.validationError = false;
    }

    !this.validationStore.data.validationOK && window.scrollTo({top: 0, behavior: 'smooth'});
  }

  handleCardNumberField(fieldType, display, color, message) {
    Spreedly.setStyle(fieldType, `border-color: ${color}`);
    this.validationStore.data.validationError = display;
    this.validationStore.data.validationMessage = message;

    if (this.validationStore.data.merchant === vclubApp || this.validationStore.data.merchant === vplusApp) {
      this.validationStore.data.validationMessage = message;
     } else {
      this.toggleErrorMessage(display, message);
    }
  }

  handleCvvNumberField(fieldType, display, color, message) {
    Spreedly.setStyle(fieldType, `border-color: ${color}`);
    this.validationStore.data.validationError = display;

    if (this.validationStore.data.merchant === vclubApp || this.validationStore.data.merchant === vplusApp) {
      this.validationStore.data.validationMessage = message;
    } else {
      this.toggleErrorMessage(display, message);
    }
  }

  toggleErrorMessage(toggle, message = '') {
    const erroMessage = document.querySelector('#validationNotificationError');
    erroMessage.textContent = message;
    erroMessage.style.display = toggle ? 'block' : 'none';
  }
}

export default SpreedlyValidationsHandler;
