/**
 * this import is necessary to get the name of the indentificator
 * of the end of a transition according the browser
 */
import misc from './misc';

const COOKIE_NAME = 'fullbarNotificationClosed';
const NOTIFICATION_SELECTOR = '.fullbar-notification';

/**
 * Object for the notification bar of the page.
 *  - Properties:
 *    + list: list of elements in DOM that are a notification bar
 *    + cookiesList: list of cookies of the browser
 *    + cookie: the cookie that is created to control that the user has closed the notification bar
 *    + transitions: id of the transition according to the browser
 *  - Methods:
 *    + init: instructions to execute on init the object
 *    + onClick: event to execute when the user clicks over the 'close' button of the notification bar
 */
class SubscriptionsNotifications {

  constructor() {
    this.transitions = misc.getTransitions();
  }

  /**
   * method init
   */
  init() {
    // Notifications containers
    const container = document.querySelector('#notifications-container');

    // check if the cookie exits
    const cookieExists = document.cookie.split(';')
      .findIndex((item) => {
        return (item.indexOf(COOKIE_NAME) !== -1);
      }) !== -1;
    if (cookieExists) {
      // Everything is hidden: exit
      return;
    }

    // TODO: hide notifications selectively
    container.querySelectorAll(NOTIFICATION_SELECTOR).forEach((item) => {
      item.style.display = 'block';
    });

    container.addEventListener('click', (evt) => {
      evt.stopPropagation();

      const notification = evt.target.closest(NOTIFICATION_SELECTOR);
      if (!evt.target.classList.contains('close') ||
        notification.classList.contains('static-notification')) {

        // Not a close button, or static notification (non-closeable)
        return false;
      }

      // write a cookie to not show the bar until the next open browser time
      // FIXME: this hides everything...
      document.cookie = `${COOKIE_NAME}=1;`;

      // add a class and modify the style of the notification bar to hide it
      notification.style.height = `${notification.getBoundingClientRect().height}px`;
      notification.classList.add('closed');
      setTimeout(() => { notification.style.height = '0'; }, 10);

      // on finish the transition, hides completely the notification bar and removes the listener
      const afterCloseTransition = () => {
        notification.style.height = '';
        notification.style.display = 'none';
        notification.removeEventListener(this.transitions, afterCloseTransition);
      };

      // add a listener to the notification bar to when the transition finish
      notification.addEventListener(this.transitions, afterCloseTransition);
    });
  }
};

const subscriptionsNotifications = new SubscriptionsNotifications();
subscriptionsNotifications.init();
