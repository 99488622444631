const _toggleError = Symbol('_toggleError');

export class ValueMatchValidator {

  constructor(options = {}) {
    this.errorType = 'errorType' in options ? options.errorType : 'valueMismatch';
  }

  validate(el) {
    let valid;
    if (el.hasAttribute('rel')) {
      const matchedElName = el.getAttribute('rel');
      const matchedEl = el.form.querySelector(`*[name=${matchedElName}]`);

      valid = el.value === matchedEl.value;
      this[_toggleError](el, valid);

    } else {
      console.warn('[ValueMatchValidator] No "rel" attribute found: validation skipped for element', el);
      valid = true;
    }

    return valid;
  }

  [_toggleError](el, valid) {
    if (valid) {
      el.setCustomValidity('');
      el.removeAttribute('data-validation-type');

    } else {
      el.setCustomValidity('value-mismatch');
      el.setAttribute('data-validation-type', this.errorType);
    }
  }
}
