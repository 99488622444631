const _doCopy = Symbol('_doCopy');
const _reset = Symbol('_reset');
const _resetFormElement = Symbol('_resetFormElement');

export class FormCopier {

  constructor(options) {
    this.trigger = document.querySelector(options.triggerSelector);
    this.copyFrom = document.querySelector('#' + options.fromId);
    this.copyTo = document.querySelector('#' + options.toId);
    this.paymentAddressSelector = this.copyTo[options.validatorCheckbox];

    // If there's no forms or trigger is trigger not a checkbox
    if (!this.trigger || !this.copyFrom || !this.copyTo || this.trigger.type !== 'checkbox') {
      console.error('Cannot initialize the form copy: invalid parameters');
      return false;
    }

    this.copyMap = new Map();
    for (let i = 0; i < this.copyFrom.elements.length; i++) {
      const source = this.copyFrom.elements[i];
      var destName = source.dataset.copyTarget ? source.dataset.copyTarget : source.name;
      var dest = this.copyTo[destName];
      if (dest) {
        this.copyMap.set(source.name, destName);
      } else {
        console.warn(`Element ${destName} not existing in the target form: skipping`);
      }
    }
  }

  init() {
    this.trigger.addEventListener('change', (evt) => {
      if (evt.currentTarget.checked) {
        this[_doCopy]();
      } else {
        this[_reset]();
      }
    });
  }

  [_doCopy]() {
    for (const [sourceName, destName] of this.copyMap) {
      try {
        this.copyTo[destName].value = this.copyFrom.elements[sourceName].value;
        this.copyTo[destName].dispatchEvent(new Event('change', { bubbles: true }));
        this.copyTo[destName].dispatchEvent(new Event('input', { bubbles: true }));
      } catch (error) {
        console.error(`Unable to copy value from #${this.copyFrom.id}.${sourceName} to #${this.copyTo.id}.${destName}`);
      }
    }
  }

  [_reset]() {
    for (const destName of this.copyMap.values()) {
      this[_resetFormElement](this.copyTo[destName]);
      this.copyTo[destName].dispatchEvent(new Event('change', { bubbles: true }));
      this.copyTo[destName].dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  [_resetFormElement](el) {
    const fieldType = el.type.toLowerCase();
    switch (fieldType) {
      case 'text':
      case 'password':
      case 'textarea':
      case 'hidden':
        el.value = el.defaultValue;
        break;
      case 'radio':
      case 'checkbox':
        if (el.checked) {
          el.checked = el.defaultChecked;
        }
        break;
      case 'select-one':
      case 'select-multiple':
        el.selectedIndex = -1;
        for (let i = 0; i < el.options.length; i++) {
          el.options[i].selected = el.options[i].defaultSelected;
        }
        break;
      default:
        break;
    }
  }

}
