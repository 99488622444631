const _isDateValid = Symbol('_isDateValid');
const _toggleError = Symbol('_toggleError');
const _validateOptions = Symbol('_validateOptions');
import { differenceInYears, parse } from 'date-fns';

export class FieldsetDateValidator {

  constructor(options) {
    this[_validateOptions](options);

    this.fieldset = document.getElementById(options.fieldsetId);
    this.dayField = document.getElementById(options.dayId);
    this.monthField = document.getElementById(options.monthId);
    this.yearField = document.getElementById(options.yearId);

    this.errorType = 'errorType' in options ? options.errorType : 'dateInvalid';
    this.copyTo = 'copyToId' in options ? document.getElementById(options.copyToId) : null;

    this.minAge = options.minAge;

    // Will be set on registering
    this.validator = null;

    this.fieldset.addEventListener('input', (evt) => {
      // Take control over default validator by capturing the event
      evt.stopPropagation();

      this.validator.clearStatus(this.dayField);
      this.validator.clearStatus(this.monthField);
      this.validator.clearStatus(this.yearField);
      this.validator.clearStatus(this.fieldset);
    });
  }

  validate(el) {
    const day = this.dayField.value;
    const month = this.monthField.value;
    const year = this.yearField.value;

    let valid = this[_isDateValid](parseInt(day), parseInt(month), parseInt(year));

    if (valid && this.copyTo) {
      this.copyTo.value = `${year}-${month}-${day}`;

    } else if (!valid && this.copyTo) {
      this.copyTo.value = '';
    }

    if (valid && Boolean(this.minAge)) {
      const enteredDate = parse(`${parseInt(day)}-${parseInt(month)}-${parseInt(year)}`, 'd-M-yyyy', new Date());
      const age = differenceInYears(new Date(), enteredDate);
      valid = age >= this.minAge;
    }

    this[_toggleError](el, valid);

    return valid;
  }

  [_isDateValid](day, month, year) {
    const daysInMonth = (m, y) => { // m is 0-based: 0-11
      switch (m) {
        case 2:
          return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
        case 4:
        case 6:
        case 9:
        case 11:
          return 30;
        default:
          return 31;
      }
    };

    return year && month >= 1 && month <= 12 && day >= 1 && day <= daysInMonth(month, year);
  }

  [_toggleError](el, valid) {
    if (valid) {
      el.setCustomValidity('');
      el.removeAttribute('data-validation-type');
      this.validator.markValid(this.dayField, true);
      this.validator.markValid(this.monthField, true);
      this.validator.markValid(this.yearField, true);

    } else {
      el.setCustomValidity('value-mismatch');
      el.setAttribute('data-validation-type', this.errorType);
      this.validator.markInvalid(this.dayField, 'value-mismatch');
      this.validator.markInvalid(this.monthField, 'value-mismatch');
      this.validator.markInvalid(this.yearField, 'value-mismatch');
    }
  }

  [_validateOptions](options) {
    if (!('fieldsetId' in options) || !document.getElementById(options.fieldsetId)) {
      throw new Error('Cannot initialize FieldsetDateValidator: missing fieldsetId');
    }
    if (!('dayId' in options) || !document.getElementById(options.dayId)) {
      throw new Error('Cannot initialize FieldsetDateValidator: missing dayId');
    }
    if (!('monthId' in options) || !document.getElementById(options.monthId)) {
      throw new Error('Cannot initialize FieldsetDateValidator: missing monthId');
    }
    if (!('yearId' in options) || !document.getElementById(options.yearId)) {
      throw new Error('Cannot initialize FieldsetDateValidator: missing yearId');
    }
  }
};
